export default [
  {
    title: 'Regions',
    // icon: 'HomeIcon',
    image: 'zones.png',
    children: [
      {
        title: 'Liste des regions',
        route: 'regions-liste',
        icon: 'ListIcon',
      },
      {
        title: 'Zones',
        route: 'zones-liste',
        icon: 'SettingsIcon',
      },
    ],
  },
]
