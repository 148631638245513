/*

Array of object

Top level object can be:
1. Header
2. Group (Group can have navItems as children)
3. navItem

* Supported Options

/--- Header ---/

header

/--- nav Grp ---/

title
icon (if it's on top level)
tag
tagVariant
children

/--- nav Item ---/

icon (if it's on top level)
title
route: [route_obj/route_name] (I have to resolve name somehow from the route obj)
tag
tagVariant

*/

import dashboard from './dashboard'
// import caisse from './caisse'
// import pharmacie from './pharmacie'
// import laboratoire from './laboratoire'
// import dossiersPatients from './dossiers-patients'
// import consultations from './consultations'
// import imagerie from './imagerie'
// import finance from './finance'
import tableauxDeBord from '@/navigation/vertical/afridia/tableaux_de_bord'
import regions from '@/navigation/vertical/afridia/regions'
import commerciaux from '@/navigation/vertical/afridia/commerciaux'
import distributeurs from '@/navigation/vertical/afridia/distributeurs'
import pointsDeVente from '@/navigation/vertical/afridia/points_de_vente'
import tourners from '@/navigation/vertical/afridia/tourners'
import geolocalisations from '@/navigation/vertical/afridia/geolocalisations'
import parametres from '@/navigation/vertical/afridia/parametres'

// Array of sections
// export default [
//   ...dashboard,
//   ...caisse,
//   ...dossiersPatients,
//   ...consultations,
//   ...pharmacie,
//   ...laboratoire,
//   ...imagerie,
//   ...finance,
//   ...parametres,
// ]

export default [
  // ...dashboard,
  ...tableauxDeBord,
  ...regions,
  ...commerciaux,
  ...distributeurs,
  ...pointsDeVente,
  ...tourners,
  // ...ventes,
  // ...stocks,
  // ...equipements,
  ...geolocalisations,
  ...parametres,
]
